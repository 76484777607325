import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isLogin: "0",
    name: "",
    token: "",
    userCode: "",
    industry: "",
    submitObj: {},
    baseURL: "",
  },
  mutations: {
    baseURL(state, payload) {
      state.baseURL = payload;
    },
    isLogin(state, payload) {
      state.isLogin = payload;
    },
    name(state, payload) {
      state.name = payload;
    },
    token(state, payload) {
      state.token = payload;
    },
    userCode(state, payload) {
      state.userCode = payload;
    },
    industry(state, payload) {
      state.industry = payload;
    },
    submitObj(state, payload) {
      state.submitObj = payload;
    },
  },
  actions: {
    baseURL(context, payload) {
      context.commit("baseURL", payload);
    },
    isLogin(context, payload) {
      context.commit("isLogin", payload);
    },
    name(context, payload) {
      context.commit("name", payload);
    },
    token(context, payload) {
      context.commit("token", payload);
    },
    userCode(context, payload) {
      context.commit("userCode", payload);
    },
    industry(context, payload) {
      context.commit("industry", payload);
    },
    submitObj(context, payload) {
      context.commit("submitObj", payload);
    },
  },
  modules: {},
});
