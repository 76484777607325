import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import md5 from "js-md5";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

/* 

*/
if (window.location.href.search(/safe/) > 0) {
  if (window.location.href.search(/safetest/) > 0) {
    axios.defaults.baseURL = "http://safetest.zhonghuijingji.com";
    store.dispatch("baseURL", "http://safetest.zhonghuijingji.com");
  } else {
    axios.defaults.baseURL = "https://safe.zhonghuijingji.com";
    store.dispatch("baseURL", "https://safe.zhonghuijingji.com");
  }
} else {
  axios.defaults.baseURL = "http://safetest.zhonghuijingji.com";
  store.dispatch("baseURL", "http://safetest.zhonghuijingji.com");
}

Vue.use(require("vue-moment"));

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import Cookies from "js-cookie";
Vue.prototype.$Cookies = Cookies;

Vue.prototype.md5 = md5;

Vue.config.productionTip = false;

axios.interceptors.request.use(
  function(config) {
    if (store.state.token) {
      config.headers.Authorization = `Bearer ${store.state.token}`;
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (
      response.data.code == 10042 ||
      response.data.code == 20000 ||
      response.code == "401 Unauthorized"
    ) {
      ElementUI.Message({
        message: "token过期请重新登录",
        type: "warning",
      });
      //delete login
      store.dispatch("isLogin", "").then(() => {
        Cookies.remove("isLogin");
      });
      store.dispatch("userCode", "").then(() => {
        Cookies.remove("userCode");
      });
      store.dispatch("name", "").then(() => {
        Cookies.remove("name");
      });
      store.dispatch("token", "").then(() => {
        Cookies.remove("token");
        router.push("/login");
      });
    }

    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status &&
      error.response.status == 401
    ) {
      ElementUI.Message({
        message: "需要先登录",
        type: "warning",
      });
      store.dispatch("isLogin", "").then(() => {
        Cookies.remove("isLogin");
      });
      store.dispatch("userCode", "").then(() => {
        Cookies.remove("userCode");
      });
      store.dispatch("name", "").then(() => {
        Cookies.remove("name");
      });
      store.dispatch("token", "").then(() => {
        Cookies.remove("token");
        router.push("/login");
      });
      router.push("/login");
      return;
    } else {
      return Promise.reject(error);
    }
  }
);

router.beforeEach((to, from, next) => {
  if (to.fullPath.indexOf("userCenter") >= 0 && store.state.token == "") {
    //token放query中
    router.push("/");
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

