<template>
  <div>
    <div class="container">
      <div class="navWrap">
        <img
          src="../assets/images/logo.png"
          alt=""
          class="logoImg"
          @click="jumpTo('/')"
          style="cursor: pointer"
        />

        <el-menu
          :default-active="computedPath"
          class="el-menu-demo menuPos"
          mode="horizontal"
          @select="handleSelect"
          active-text-color="#f6ab01"
          router
        >
          <el-menu-item
            :index="item.path"
            v-for="(item, i) in navList"
            :key="i"
            >{{ item.name }}</el-menu-item
          >
        </el-menu>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {},
  data() {
    return {
      /* nav */
      activeIndex: "1",
      navList: [
        { path: "/", name: "首页" },
        { path: "/product", name: "在线投保" },
        { path: "/about", name: "关于我们" },
        /* 
       
        */
      ],
    };
  },
  methods: {
    /* nav */
    handleSelect(key) {
      // this.activeIndex = key[0];
    },
    jumpTo(path) {
      if (this.$route.path != path) {
        this.$router.push(path);
      }
    },
  },
  computed: {
    computedPath() {
      if (this.$route.path.indexOf("/product") > 0) {
        return "/product";
      }else {
        return this.$route.path;
      }
    },
  },
};
</script>


<style scoped>
.container {
  background: white;
  border-bottom: 1px solid #f1f1f1;
}
.navWrap {
  margin: 0 auto;
  width: 1200px;
  height: 90px;
  line-height: 34px;
  display: flex;
  /*  justify-content: space-between; */
  align-items: center;
}
.logoImg {
  margin-right: 140px;
}
.menuPos {
  margin-bottom: -30px;
}
</style>