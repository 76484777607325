<template>
  <div class="wrap">
    <div class="footer-top">
      <div style="text-align: left" class="leftList">
        <p
          style="
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 10px;
            margin-top: 0;
          "
        >
          关于我们
        </p>
        <el-link type="warning" @click="link(1)" style="margin-bottom: 4px"
          >公司介绍</el-link
        >
        <el-link
          type="warning"
          style="margin-bottom: 4px"
          href="https://www.zhonghuicn.com/index.php/Home/Contact"
          >分支机构</el-link
        >
        <el-link type="warning" @click="link(3)" style="margin-bottom: 4px"
          >隐私声明</el-link
        >
        <el-link type="warning" @click="link(4)" style="margin-bottom: 4px"
          >联系我们</el-link
        >
      </div>

      <div
        style="display: flex; flex-flow: column nowrap; align-items: flex-end"
      >
        <p style="font-weight: bold; display: flex">
          <span style="font-size: 22px; margin-right: 10px; line-height: 32px"
            >全国统一服务热线</span
          >
          <span style="font-size: 28px; line-height: 32px">4001330365</span>
        </p>
        <el-link type="success" @click="link(5)" style="margin-bottom: 4px"
          >保险经纪许可证</el-link
        >
        <el-link type="success" @click="link(6)" style="margin-bottom: 4px"
          >营业执照</el-link
        >
      </div>
    </div>
    <!--  -->
    <div class="footer-bottom">
      <div
        style="
          width: 1200px;
          border-top: 1px solid #606266;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <p style="margin-bottom: 4px">
          <img
            src="https://www.zhonghuicn.com/Public/images/police-icon.png"
            alt=""
            width="16"
            height="16"
            style="margin-bottom: -2px"
          />
          <span style="line-height: 30px"
            >浙公网安备
            <el-link
              :underline="false"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302003611"
              >33010302003611</el-link
            >
          </span>
          <span style="line-height: 30px"
            >浙 ICP 备
            <el-link :underline="false" href="http://beian.miit.gov.cn/"
              >15013235号-3</el-link
            >
          </span>
        </p>
        <p style="margin-top: 4px">
          <span style="line-height: 30px"
            >Copyright© 中惠保险经纪 All Rights Reserved</span
          >
          <span style="line-height: 30px"
            >地址：南宁市青秀区南湖街道金源CBD现代城B座1015-19号房
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    link(n) {
      if (n == 5) {
        if (this.$route.path == "/brokerLicense") {
          return;
        }
        this.$router.push({ path: "/brokerLicense" });
      } else if (n == 6) {
        if (this.$route.path == "/businessLicense") {
          return;
        }
        this.$router.push({ path: "/businessLicense" });
      } else if (n == 1) {
        if (this.$route.path == "/about") {
          return;
        }
        this.$router.push({ path: "/about", query: { activeNum: 1 } });
      } else if (n == 3) {
        if (this.$route.path == "/about") {
          return;
        }
        this.$router.push({ path: "/about", query: { activeNum: 3 } });
      } else if (n == 4) {
        if (this.$route.path == "/about") {
          return;
        }
        this.$router.push({ path: "/about", query: { activeNum: 4 } });
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  background: #333333;
  display: flex;
  flex-flow: column nowrap;
}
.footer-top {
  color: #fff;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
  padding-top: 20px;
}

.footer-bottom {
  color: #90908f;
}
.footer-bottom span {
  margin-right: 10px;
  margin-left: 10px;
}
.leftList {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 30px;
  padding-right: 40px;
  border-right: 1px solid #606266;
}
</style>
