<template>
  <div>
    <Top />
    <TopNav />
    <Foot />
  </div>
</template>

<script>
// @ is an alias to /src
import Top from "@/components/Top.vue";
import TopNav from "@/components/TopNav.vue";
import Foot from "@/components/Foot.vue";

export default {
  components: {
    Top,
    TopNav,
    Foot,
  },
  mounted() {
    if (
      this.$Cookies.get("name") &&
      this.$Cookies.get("token") &&
      this.$Cookies.get("isLogin")
    ) {
      this.$store.dispatch("name", this.$Cookies.get("name"));
      this.$store.dispatch("token", this.$Cookies.get("token"));
      this.$store.dispatch("isLogin", this.$Cookies.get("isLogin"));
    } else {
      this.$Cookies.remove("name");
      this.$Cookies.remove("token");
      this.$Cookies.remove("isLogin");
    }
  },
};
</script>
<style scoped>
</style>
