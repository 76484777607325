<template>
  <div class="container">
    <div class="header">
      <div>全国服务热线：4001330365</div>

      <div style="display: flex; align-items: center">
        <span v-if="computedIsLogin == 1">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link" style="cursor: pointer">
              你好,{{ $store.state.name
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1" v-if="data != 'userCenterTop'"
                >用户中心</el-dropdown-item
              >
              <el-dropdown-item command="2">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>

        <span v-else>
          <el-link :underline="false" @click="login">请登录</el-link>
          <el-link
            :underline="false"
            @click="register"
            style="margin-left: 20px"
            >注册</el-link
          >
        </span>

        <el-popover placement="bottom" width="220" trigger="hover">
          <img src="../assets/images/QR.jpg" alt="" style="left: 0" />
          <div slot="reference" class="Rborder">
            <svg class="icon" viewBox="0 0 1024 1024" width="18" height="18">
              <path
                d="M693.12 347.264c11.776 0 23.36 0.896 35.008 2.176-31.36-146.048-187.456-254.528-365.696-254.528C163.2 94.912 0 230.656 0 403.136c0 99.52 54.272 181.248 145.024 244.736L108.8 756.864l126.72-63.488c45.312 8.896 81.664 18.112 126.912 18.112 11.392 0 22.656-0.512 33.792-1.344-7.04-24.256-11.2-49.6-11.2-76.032 0.064-158.336 136-286.848 308.096-286.848z m-194.816-98.24c27.392 0 45.376 17.984 45.376 45.248 0 27.136-17.984 45.312-45.376 45.312-27.072 0-54.336-18.176-54.336-45.312 0-27.328 27.2-45.248 54.336-45.248z m-253.632 90.56c-27.2 0-54.592-18.176-54.592-45.312 0-27.264 27.392-45.248 54.592-45.248s45.248 17.92 45.248 45.248c0 27.136-18.048 45.312-45.248 45.312zM1024 629.76c0-144.896-145.024-262.976-307.904-262.976-172.48 0-308.224 118.144-308.224 262.976 0 145.28 135.808 262.976 308.224 262.976 36.096 0 72.512-9.024 108.736-18.112l99.392 54.528-27.264-90.624C969.728 783.872 1024 711.488 1024 629.76z m-407.872-45.376c-17.984 0-36.224-17.92-36.224-36.224 0-18.048 18.24-36.224 36.224-36.224 27.52 0 45.376 18.176 45.376 36.224 0 18.304-17.856 36.224-45.376 36.224z m199.36 0c-17.856 0-36.032-17.92-36.032-36.224 0-18.048 18.112-36.224 36.032-36.224 27.264 0 45.376 18.176 45.376 36.224 0 18.304-18.112 36.224-45.376 36.224z"
                fill="#67C23A"
              ></path>
            </svg>
            <el-link :underline="false"> 微信号 </el-link>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    login() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleCommand(c) {
      if (c == 1) {
        this.$router.push("/userCenter");
      } else {
        this.$store.dispatch("name", "").then(() => {
          this.$Cookies.remove("name");
        });
        this.$store.dispatch("token", "").then(() => {
          this.$Cookies.remove("token");
        });
        this.$store.dispatch("isLogin", "0").then(() => {
          this.$Cookies.remove("isLogin");
        });
         this.$router.push("/login");
      }
    },
  },
  computed: {
    computedIsLogin() {
      return this.$store.state.isLogin;
    },
  },
};
</script>

<style scoped>
.container {
  border-bottom: 1px solid #f1f1f1;
}
.header {
  margin: 0 auto;
  width: 1200px;
  height: 34px;
  line-height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Rborder {
  border-left: 1px solid #f1f1f1;
  padding-left: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.Rborder > .icon {
  margin-right: 10px;
}
</style>