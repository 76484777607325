import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Home,
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/home/Home.vue"),
      },
      {
        path: "/product",
        name: "Product",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/home/Product.vue"),
      },
      {
        path: "/product/detail",
        name: "Detail",
        meta: {
          Breadcrumb: "详情",
        },
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/Detail.vue"
          ),
      },
      {
        path: "/product/clause",
        name: "Clause",
        meta: {
          Breadcrumb: "投保须知",
        },
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/Clause.vue"
          ),
      },
      {
        path: "/product/insure",
        name: "Insure",
        meta: {
          Breadcrumb: "保费试算",
        },
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/Insure.vue"
          ),
      },
      {
        path: "/product/confirm",
        name: "Confirm",
        meta: {
          Breadcrumb: "投保成功",
        },
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/Confirm.vue"
          ),
      },
      {
        path: "/product/success",
        name: "Success",
        meta: {
          Breadcrumb: "成功",
        },
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/Success.vue"
          ),
      },
      {
        path: "/about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/home/About.vue"),
      },
    ],
  },
  {
    path: "/brokerLicense",
    name: "BrokerLicense",
    component: () =>
      import(
        /* webpackChunkName: "BrokerLicense" */ "../views/BrokerLicense.vue"
      ),
  },
  {
    path: "/businessLicense",
    name: "BusinessLicense",
    component: () =>
      import(
        /* webpackChunkName: "BusinessLicense" */ "../views/BusinessLicense.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "BusinessLicense" */ "../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "BusinessLicense" */ "../views/Register.vue"),
  },
  {
    path: "/forget",
    name: "Forget",
    component: () =>
      import(/* webpackChunkName: "BusinessLicense" */ "../views/Forget.vue"),
  },
  {
    path: "/userCenter",
    name: "",
    component: () =>
      import(/* webpackChunkName: "userCenter" */ "../views/UserCenter.vue"),
    children: [
      {
        path: "",
        name: "Users",
        meta: {
          Breadcrumb: "个人首页",
        },
        component: () =>
          import(
            /* webpackChunkName: "userCenter" */ "../components/userCenter/Users.vue"
          ),
      },
      {
        path: "/userCenter/orderlist",
        name: "Orderlist",
        meta: {
          Breadcrumb: "我的订单",
        },
        component: () =>
          import(
            /* webpackChunkName: "userCenter" */ "../components/userCenter/Orderlist.vue"
          ),
      },
      {
        path: "/userCenter/profile",
        name: "Profile",
        meta: {
          Breadcrumb: "个人信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "userCenter" */ "../components/userCenter/Profile.vue"
          ),
      },
      {
        path: "/userCenter/companyProfile",
        name: "CompanyProfile",
        meta: {
          Breadcrumb: "企业信息",
        },
        component: () =>
          import(
            /* webpackChunkName: "userCenter" */ "../components/userCenter/companyProfile.vue"
          ),
      },
      {
        path: "/userCenter/account",
        name: "Account",
        meta: {
          Breadcrumb: "账户安全",
        },
        component: () =>
          import(
            /* webpackChunkName: "userCenter" */ "../components/userCenter/Account.vue"
          ),
      },
      {
        path: "/userCenter/account/bindMobile",
        name: "BindMobile",
        meta: {
          Breadcrumb: "账户安全",
        },
        component: () =>
          import(
            /* webpackChunkName: "userCenter" */ "../components/userCenter/account/BindMobile.vue"
          ),
      },
      {
        path: "/userCenter/account/modifyPass",
        name: "ModifyPass",
        meta: {
          Breadcrumb: "账户安全",
        },
        component: () =>
          import(
            /* webpackChunkName: "userCenter" */ "../components/userCenter/account/ModifyPass.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
});

export default router;
